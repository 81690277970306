import './Table.scss';
import Pagination from '../Pagination';
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
export default defineComponent({
    name: 'Table',
    components: {
        Pagination
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        fields: {
            type: Array,
            required: true
        },
        striped: {
            type: Boolean,
            required: false,
            default: false
        },
        hover: {
            type: Boolean,
            required: false,
            default: false
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        },
        perPage: {
            type: Number,
            required: false,
            default: 100
        },
        defaultSort: {
            type: String,
            required: false,
            default: ''
        }
    },
    data: () => ({
        currentPage: 0,
        sortKey: null,
        sortDirection: 1
    }),
    computed: {
        numPages() {
            return Math.ceil(this.items.length / this.perPage);
        },
        classes() {
            const result = ['table'];
            if (this.striped)
                result.push('table-striped');
            if (this.hover)
                result.push('table-hover');
            if (this.small)
                result.push('table-sm');
            return result;
        },
        pageItems() {
            return this.sortedItems.filter((_, index) => {
                return index >= this.currentPage * this.perPage && index < (this.currentPage + 1) * this.perPage;
            });
        },
        sortedItems() {
            if (this.sortKey === null)
                return this.items;
            const key = this.sortKey;
            const items = cloneDeep(this.items);
            return items.sort((a, b) => {
                // @ts-ignore
                return a[key] > b[key] ? this.sortDirection : -this.sortDirection;
            });
        }
    },
    mounted() {
        if (this.defaultSort.length > 0) {
            this.sortKey = this.defaultSort;
            this.sortBy(this.defaultSort);
        }
    },
    methods: {
        sortBy(key) {
            this.sortDirection = this.sortKey === key ? -this.sortDirection : 1;
            this.sortKey = key;
        },
        printField(field, key) {
            if (key.indexOf('time') > 0) {
                if (Number(field) == 0) {
                    return 'niet gepland';
                }
                const startDate = new Date(Number(field) * 1000);
                return startDate.getFullYear() + "/"
                    + (startDate.getMonth() + 1) + "/"
                    + startDate.getDate() + " "
                    + startDate.getHours() + ":"
                    + startDate.getMinutes();
            }
            else {
                return field;
            }
        }
    }
});
